import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../utils/color";
import { Link } from "gatsby";

import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs";
import { EnLayout } from "../../components/layout/enLayout";

const EnProductPage = () => {
  return (
    <EnLayout title="PRODUCT | LIME" jpUrl="/product/" enUrl="/en/product/">
      <Breadcrumbs>
        <Link to="/en/">HOME</Link>
        <span>/</span>
        <Link to="/en/product/">PRODUCT</Link>
        <span>/</span>
      </Breadcrumbs>
      <SSection>
        <p>EN PRODUCT</p>
      </SSection>
    </EnLayout>
  );
};

export default EnProductPage;

const SSection = styled.section`
  background-color: ${Color.light};
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
